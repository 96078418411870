import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  Collapse,
  FormGroup,
  FormControl,
  FormHelperText,
  TextField,
  Fab,
  Grid,
} from "@material-ui/core"
import { Add, ExpandMore } from "@material-ui/icons"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Rating from "@material-ui/lab/Rating"
import clsx from "clsx"
import React, { useContext, useRef, useState } from "react"
import { ShadowContext } from "../../Shared/ShadowContext"
import jwt from "jwt-decode"
import { DateTime } from "luxon"
import { useTagSelector } from "./Hooks/UseTagSelector"
import { RecipeImage } from "./Images"

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}))

const useHeaderStyles = makeStyles({
  content: {
    flexBasis: "65%",
  },
})

const TagView = ({ tags }) => {
  const tagSelectorRef = useTagSelector(tags, () => {})

  return <tag-selector readonly ref={tagSelectorRef} tag-target="./mealplan/tag"></tag-selector>
}

const RecipeDetails = (props) => {
  const { recipe } = props
  const { recipeDescription, recipePage, recipeBook } = recipe

  const hasDetails = recipeDescription || recipePage || recipeBook

  return (
    <>
      {hasDetails ? (
        <>
          {recipeDescription ? (
            <Typography variant="body2" color="textSecondary" component="p">
              {recipeDescription}
            </Typography>
          ) : null}
          {recipeBook ? (
            <Typography variant="body2" color="textSecondary" component="p">
              {recipeBook}
            </Typography>
          ) : null}
          {recipePage ? (
            <Typography variant="body2" color="textSecondary" component="p">
              Page: {recipePage}
            </Typography>
          ) : null}
        </>
      ) : null}
    </>
  )
}

const RecipeBookAvatar = ({ book }) => {
  const titleComps = book.split(" ")

  const [firstWord = "", secondWord = ""] = titleComps

  const first = firstWord[0] || ""
  const second = secondWord[0] || ""

  return <>{`${first.toLocaleUpperCase()}${second.toLocaleUpperCase()}`}</>
}

/**
 * @param {{
 *  recipe: import("../Types").Recipe;
 *  recipeSelected: (recipe: import("../Types").Recipe) => void;
 *  small?: boolean;
 *  updateRecipe?: (recipe: import("../Types").Recipe) => void
 *  expandClick?: (recipe: import("../Types").Recipe) => void }} props
 * @returns {JSX.Element}
 */
export const RecipeCard = (props) => {
  const { recipe, small = false, updateRecipe, recipeSelected } = props

  const classes = useStyles()
  const headerClasses = useHeaderStyles()

  const [menuOpen, setMenuOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const [note, setNote] = useState("")

  const ref = useRef(null)

  const { shadowRoot } = useContext(ShadowContext)

  const handleExpandClick = (event) => {
    event.stopPropagation()

    if (props.expandClick) {
      props.expandClick(recipe)
      return
    }

    setExpanded(!expanded)
  }

  const { notes = [] } = recipe

  return (
    <Card
      style={{ height: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}
      onClick={() => {
        console.log(recipe)

        if (!recipeSelected) {
          return
        }

        recipeSelected(recipe)
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            src={recipe.recipeIcon ? `https://${recipe.recipeIcon}` : ""}
            alt={recipe.name}
            aria-label={recipe.name}
            className={classes.avatar}
          >
            {recipe.recipeBook ? <RecipeBookAvatar book={recipe.recipeBook} /> : null}
          </Avatar>
        }
        action={
          small ? null : (
            <IconButton ref={ref} aria-label="settings" onClick={() => setMenuOpen(!menuOpen)}>
              <MoreVertIcon />
            </IconButton>
          )
        }
        title={<Typography style={{ whiteSpace: "normal" }}>{recipe.name}</Typography>}
        disableTypography={true}
        classes={headerClasses}
      />
      <Menu container={ref.current} anchorEl={ref.current} open={menuOpen} onClose={() => setMenuOpen(false)}>
        <MenuItem
          onClick={() => {
            shadowRoot.dispatchEvent(new CustomEvent("edit-recipe", { detail: recipe, composed: true, bubbles: true }))
          }}
        >
          Edit
        </MenuItem>
      </Menu>
      <RecipeImage recipe={recipe} />
      <CardContent>
        {!small ? <RecipeDetails recipe={recipe} /> : null}
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Rating
            name={`recipe-rating-${recipe.id}`}
            value={recipe.rating}
            precision={0.5}
            disabled={!updateRecipe}
            onChange={(event, newValue) => {
              event.stopPropagation()

              if (!updateRecipe) {
                return
              }

              updateRecipe({ ...recipe, rating: newValue })

              return
            }}
          />
          <Typography variant="body2" color="textSecondary" component="span">
            {`Used ${recipe.usageCount || 0} times`}
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMore />
          </IconButton>
        </div>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
            <TagView tags={recipe.tags} />
            <Grid item xs={12}>
              <FormGroup>
                <FormControl margin="dense">
                  <TextField
                    multiline={true}
                    rows={4}
                    variant="outlined"
                    label="Note"
                    value={note || ""}
                    onChange={(event) => setNote(event.target.value)}
                  />
                  <FormHelperText>Add a new note</FormHelperText>
                </FormControl>
                <FormControl margin="dense">
                  <Fab
                    size="small"
                    color="primary"
                    onClick={(event) => {
                      event.stopPropagation()

                      if (!updateRecipe) {
                        return
                      }

                      const { email } = jwt(localStorage.getItem("token"))

                      const date = DateTime.now().toISO()

                      const newNote = { user: email, date, note }

                      recipe.notes = recipe.notes || []

                      recipe.notes.push(newNote)

                      updateRecipe(recipe)

                      setNote("")
                    }}
                  >
                    <Add />
                  </Fab>
                </FormControl>
              </FormGroup>
            </Grid>
            {notes.map((note, index) => {
              return (
                <Grid key={index} item xs={12}>
                  <div>
                    <Typography>{note.note}</Typography>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography variant="caption">{note.user}</Typography>
                    <Typography variant="caption">
                      {DateTime.fromISO(note.date).toFormat("dd LLL yyyy HH:mm")}
                    </Typography>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  )
}
