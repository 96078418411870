import React, { useContext, useState } from "react"
import { Grid, Typography } from "@material-ui/core"
import { RecipeCard } from "./RecipeCard"
import { RecipeCardDialog } from "./RecipeCardDialog"
import { ShadowContext } from "../../Shared/ShadowContext"

/**
 * @param {{
 *  recipes: Array<import("../Types").Recipe>;
 *  recipeSelected?: (recipe: import("../Types").Recipe) => void;
 *  updateRecipe?: (recipe: import("../Types").Recipe) => void;
 * }} props
 * @returns {JSX.Element | null}
 */
export const RecipeSlider = (props) => {
  const { recipes } = props

  const [recipe, setRecipe] = useState(null)
  const { shadowRoot } = useContext(ShadowContext)

  return (
    <Grid id="container" container spacing={2} style={{ margin: 0, width: "100%" }}>
      <div
        style={{
          overflow: "hidden",
          padding: 20,
          whiteSpace: "nowrap",
          overflowX: "auto",
          flexWrap: "nowrap",
          display: "flex",
        }}
      >
        {recipes.map((recipe, index) => {
          const { recipeSelected, updateRecipe } = props

          return (
            <div key={index} style={{ width: 300, marginRight: 24, display: "inline-block", height: "100%" }}>
              <RecipeCard
                recipe={recipe}
                recipeSelected={(recipe) => recipeSelected && recipeSelected(recipe)}
                updateRecipe={(recipe) => updateRecipe && updateRecipe(recipe)}
                expandClick={(recipe) => {
                  setRecipe(recipe)
                }}
              />
            </div>
          )
        })}
      </div>
      {recipe ? (
        <RecipeCardDialog
          recipe={recipe}
          open={!!recipe}
          onClose={() => {
            setRecipe(null)
          }}
          shadowRoot={shadowRoot}
        />
      ) : null}
    </Grid>
  )
}
