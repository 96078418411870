import React from "react"
import { CardMedia, makeStyles } from "@material-ui/core"
import { ImageDisplay } from "./ImageList"

const useStyles = makeStyles((theme) => ({
  // root: {
  //   maxWidth: 345,
  // },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    //backgroundColor: red[500],
  },
}))

export const RecipeImage = (props) => {
  const classes = useStyles()

  const { recipeImages, recipeImageUrl, name, recipeUrl } = props.recipe

  const { height, minHeight } = props

  return (
    <>
      {recipeImageUrl ? (
        <CardMedia
          onClick={() => {
            if (!recipeUrl) {
              return
            }

            window.open(recipeUrl, "_blank")
          }}
          style={{ height, flex: 1 }}
          className={classes.media}
          image={recipeImageUrl}
          title={name}
        />
      ) : null}
      {recipeImages ? (
        <ImageDisplay
          minHeight={minHeight}
          height={height}
          thumbnail={true}
          files={recipeImages}
          file={recipeImages[0]}
        />
      ) : null}
    </>
  )
}

export const BookImage = (props) => {
  const { bookImages } = props.book

  const { height, minHeight } = props

  return (
    <>
      {bookImages ? (
        <ImageDisplay
          clickDisabled={props.clickDisabled}
          minHeight={minHeight}
          height={height}
          thumbnail={true}
          files={bookImages}
          file={bookImages[0]}
        />
      ) : null}
    </>
  )
}
