import ReactDOM from "react-dom"
import React from "react"
import { create } from "jss"
import { createMuiTheme } from "@material-ui/core"
import { ThemeProvider, StylesProvider, jssPreset } from "@material-ui/styles"
import { ShadowContext } from "./ShadowContext"

const theme = createMuiTheme()

export default class ReactMaterialComponentBase extends HTMLElement {
  constructor() {
    super()

    this.shadow = this.attachShadow({ mode: "open" })
    this.mountPoint = document.createElement("div")
    this.stylesMountPoint = document.createElement("div")
    this.shadow.appendChild(this.mountPoint)
    this.shadow.appendChild(this.stylesMountPoint)

    this.sheetsManager = new Map()

    this.jsxRootComponent = () => <div></div>
  }

  connectedCallback() {
    super.connectedCallback && super.connectedCallback()

    this.render()
  }

  onSuccess(message) {
    this.shadow &&
      this.shadow.dispatchEvent(
        new CustomEvent("success-message", {
          composed: true,
          bubbles: true,
          detail: {
            message: message || "Operation successful",
          },
        })
      )
  }

  onError(error) {
    let message = "Operation failed"

    if (typeof error === "string") {
      message = error
    }

    if (error instanceof Error) {
      message = error.message
    }

    this.shadow &&
      this.shadow.dispatchEvent(
        new CustomEvent("failure-message", {
          composed: true,
          bubbles: true,
          detail: {
            message,
          },
        })
      )
  }

  async parseError(response, defaultErrorMessage) {
    let error = defaultErrorMessage

    try {
      error = (await response.json()).error
    } catch (_) {
      error = defaultErrorMessage
    }

    throw new Error(error)
  }

  render() {
    const jss = create({
      ...jssPreset(),
      insertionPoint: this.stylesMountPoint,
    })

    ReactDOM.render(
      <StylesProvider sheetsManager={this.sheetsManager} jss={jss}>
        <ThemeProvider theme={theme}>
          <ShadowContext.Provider value={{ shadowRoot: this.shadow }}>{this.jsxRootComponent()}</ShadowContext.Provider>
        </ThemeProvider>
      </StylesProvider>,
      this.mountPoint
    )
  }
}
