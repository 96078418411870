import { useEffect, useRef } from "react"

export function useTagSelector(tags, setTags) {
  const tagSelectorRef = useRef(/** @type {HTMLElement | null} */ (null))

  useEffect(() => {
    if (!tagSelectorRef.current) {
      return
    }

    const { current } = tagSelectorRef

    function tagsSelected(event) {
      const { detail } = event

      setTags(detail)
    }

    current.addEventListener("tags-selected", tagsSelected)

    return () => {
      current.removeEventListener("tags-selected", tagsSelected)
    }
  }, [tagSelectorRef, setTags])

  useEffect(() => {
    const { current } = tagSelectorRef

    if (!current || !tags) {
      return
    }

    current.setSelectedTags(tags || [])
  }, [tagSelectorRef, tags])

  return tagSelectorRef
}
