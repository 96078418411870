import {
  Button,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { Add, Edit } from "@material-ui/icons"
import { ImageUploader } from "./ImageSelector"
import { getImages, ImageDisplay } from "./ImageList"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { RecipeSlider } from "./RecipeSlider"

const OnlineRecipe = (props) => {
  const { recipe, setRecipe } = props

  return (
    <>
      <FormControl margin="normal">
        <TextField
          variant="outlined"
          label="Recipe Url"
          value={recipe.recipeUrl || ""}
          onChange={(event) => setRecipe({ ...recipe, recipeUrl: event.target.value })}
        />
        <FormHelperText>The url of the website the recipe is from</FormHelperText>
      </FormControl>
      <FormControl margin="normal">
        <TextField
          variant="outlined"
          label="Recipe Description"
          value={recipe.recipeDescription || ""}
          onChange={(event) => setRecipe({ ...recipe, recipeDescription: event.target.value })}
        />
        <FormHelperText>Optional description of the recipe</FormHelperText>
      </FormControl>
      <FormControl margin="normal">
        <TextField
          variant="outlined"
          label="Recipe Image"
          value={recipe.recipeImageUrl || ""}
          onChange={(event) => setRecipe({ ...recipe, recipeImageUrl: event.target.value })}
        />
        <FormHelperText>Picture of the recipe from the website</FormHelperText>
      </FormControl>
    </>
  )
}

function removeRecipeImage(recipe, image, setRecipe) {
  const recipeImages = recipe.recipeImages || []

  recipe.recipeImages = recipeImages.filter((ri) => ri.fileName !== image.fileName)

  setRecipe({ ...recipe })
}

const BookRecipe = (props) => {
  const { recipe, setRecipe, bookUrl } = props

  const [books, setBooks] = useState([])

  const ref = useRef(null)

  async function searchBooks(searchTerm) {
    const token = await window.authorise()

    const bookResponse = await fetch(`${bookUrl}/${searchTerm}`, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    })

    const books = await bookResponse.json()

    setBooks(books)
  }

  return (
    <>
      <FormControl margin="normal">
        <div ref={ref}>
          <Autocomplete
            value={recipe.recipeBook || ""}
            freeSolo
            options={books.map((book) => book.name)}
            onChange={(event, newValue) => {
              setRecipe({ ...recipe, recipeBook: newValue })
              if (newValue) {
                searchBooks(newValue)
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Book or magazine"
                onChange={(event) => {
                  const newValue = event.target.value

                  setRecipe({ ...recipe, recipeBook: newValue })
                  if (newValue) {
                    searchBooks(newValue)
                  }
                }}
              />
            )}
            PopperComponent={(props) => (
              <Popper {...props} placement="bottom-start" container={ref.current} anchorEl={ref.current} />
            )}
          />
        </div>
        <FormHelperText>The name of the book or magazine the recipe is from</FormHelperText>
      </FormControl>
      <FormControl margin="normal">
        <TextField
          variant="outlined"
          label="Page"
          type="number"
          value={recipe.recipePage || ""}
          onChange={(event) => setRecipe({ ...recipe, recipePage: event.target.value })}
        />
        <FormHelperText>The name of the book or magazine the recipe is from</FormHelperText>
      </FormControl>
      <FormControl margin="normal">
        <TextField
          variant="outlined"
          label="Recipe Description"
          value={recipe.recipeDescription || ""}
          onChange={(event) => setRecipe({ ...recipe, recipeDescription: event.target.value })}
        />
        <FormHelperText>Optional description of the recipe</FormHelperText>
      </FormControl>
      <FormControl margin="normal" style={{ width: "100%" }}>
        <div style={{ display: "block", overflow: "hidden", overflowX: "auto", whiteSpace: "nowrap" }}>
          {(recipe.recipeImages || []).map((item, index) => {
            return (
              <div key={index} style={{ display: "inline-block", paddingRight: 20 }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <ImageDisplay thumbnail={true} file={item} />
                  <Button onClick={() => removeRecipeImage(recipe, item, setRecipe)}>CLEAR</Button>
                </div>
              </div>
            )
          })}
        </div>
        <FormHelperText>Add pictures of the book or recipe</FormHelperText>
      </FormControl>
      <FormControl margin="normal">
        <ImageUploader
          onUploaded={({ fileName, thumbFileName }) => {
            setRecipe({ ...recipe, recipeImages: [...(recipe.recipeImages || []), { fileName, thumbFileName }] })
          }}
        />
      </FormControl>
    </>
  )
}

/**
 *
 * @param {{
 * createRecipe: (recipe: import("../Types").Recipe) => void,
 * bookUrl: string; recipe?: import("../Types").Recipe | undefined,
 * searchRecipes?: (recipe: Partial<import("../Types").Recipe>) => Promise<import("../Types").Recipe[]>,
 * updateRecipe?: (recipe: import("../Types").Recipe, onComplete: () => Promise<void>) => void
 * }} props
 * @returns {JSX.Element}
 */
export const CreateRecipe = (props) => {
  /**
   * @type {Partial<import("../Types").Recipe>}
   */
  const initialRecipeState = props.recipe || {}

  const [recipe, setRecipe] = useState(initialRecipeState)
  const [bookRecipe, setBookRecipe] = useState(false)
  const [similarRecipes, setSimilarRecipes] = useState(/** @type {import("../Types").Recipe[]} */ ([]))

  const tagSelectorRef = useRef(/** @type {HTMLElement | null} */ (null))

  useEffect(() => {
    if (!tagSelectorRef.current) {
      return
    }

    const { current } = tagSelectorRef

    function tagsSelected(event) {
      const { detail } = event

      setRecipe({ ...recipe, tags: detail || [] })
      current.setSelectedTags(detail || [])
    }

    current.addEventListener("tags-selected", tagsSelected)

    return () => {
      current.removeEventListener("tags-selected", tagsSelected)
    }
  }, [tagSelectorRef, recipe])

  useEffect(() => {
    const { current } = tagSelectorRef
    const { recipe } = props

    if (!current || !recipe) {
      return
    }

    current.setSelectedTags(recipe.tags || [])
  }, [tagSelectorRef, props.recipe])

  useEffect(() => {
    setRecipe(props.recipe || {})

    if (!props.recipe) {
      return
    }

    const { recipe } = props

    setBookRecipe(!!recipe.recipeBook)
  }, [props.recipe])

  async function searchSimilarRecipes() {
    setSimilarRecipes([])

    const { searchRecipes } = props

    if (!searchRecipes) {
      return
    }

    const recipes = await searchRecipes(recipe)

    setSimilarRecipes(recipes)
  }

  useEffect(() => {
    searchSimilarRecipes()
  }, [recipe])

  return (
    <FormGroup>
      <FormControl margin="normal">
        <TextField
          variant="outlined"
          label="Recipe Name"
          value={recipe.name || ""}
          onChange={(event) => setRecipe({ ...recipe, name: event.target.value })}
        />
        <FormHelperText>The name of the recipe</FormHelperText>
      </FormControl>
      <FormControl margin="normal">
        <FormControlLabel
          control={
            <Checkbox
              checked={bookRecipe}
              onChange={() => setBookRecipe(!bookRecipe)}
              name="book-recipe"
              color="primary"
            />
          }
          label="Book Recipe"
        />
        <FormHelperText>Recipe is from a book or magazine</FormHelperText>
      </FormControl>
      {bookRecipe ? (
        <BookRecipe bookUrl={props.bookUrl} recipe={recipe} setRecipe={setRecipe} />
      ) : (
        <OnlineRecipe recipe={recipe} setRecipe={setRecipe} />
      )}

      <tag-selector create-enabled ref={tagSelectorRef} tag-target="./mealplan/tag"></tag-selector>

      {similarRecipes && similarRecipes.length ? (
        <>
          <Typography>{"We've found the following similar recipes"}</Typography>
          <RecipeSlider
            recipes={similarRecipes}
            updateRecipe={(recipe) => props.updateRecipe(recipe, () => searchSimilarRecipes())}
          />
        </>
      ) : null}

      <FormControl margin="normal" style={{ alignItems: "flex-start" }}>
        <Tooltip title={<Typography>{props.recipe ? "Update Recipe" : "Create Recipe"}</Typography>}>
          <Fab
            color="primary"
            variant="extended"
            onClick={() => {
              props.createRecipe(recipe)
              setRecipe(initialRecipeState)
            }}
          >
            {props.recipe ? (
              <>
                <Edit /> Update
              </>
            ) : (
              <>
                <Add /> Create
              </>
            )}
          </Fab>
        </Tooltip>
      </FormControl>
    </FormGroup>
  )
}
