import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { CreateRecipe as ReactCreateRecipe } from "../React/CreateRecipe"
import React from "react"

export default class CreateRecipe extends ReactMaterialComponentBase {
  static get observedAttributes() {
    return ["target", "book-target"]
  }

  constructor() {
    super()

    this.jsxRootComponent = () => (
      <ReactCreateRecipe
        bookUrl={this.bookUrl}
        createRecipe={(recipe) => this.create(recipe)}
        searchRecipes={(recipe) => this.searchSimilarRecipes(recipe)}
        updateRecipe={(recipe, onComplete) => this.updateRecipe(recipe, onComplete)}
      />
    )
  }

  /**
   * @param {import("../Types").Recipe} recipe
   */
  async create(recipe) {
    try {
      const token = await window.authorise()

      const createdRecipe = await fetch(`${this.getAttribute("target")}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(recipe),
      })

      if (!createdRecipe.ok) {
        throw Error("Failed to create recipe")
      }

      this.onSuccess && this.onSuccess("Recipe created")
    } catch (error) {
      this.onError && this.onError("Failed to create recipe")
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "book-target") {
      this.bookUrl = newValue

      this.render()
    }
  }

  async searchSimilarRecipes(recipe) {
    try {
      const { recipeBook, recipeUrl, name } = recipe || {}

      if (!name) {
        return
      }

      if (!recipeBook && !recipeUrl) {
        return
      }

      const token = await window.authorise()

      const recipesResponse = await fetch(`${this.getAttribute("target")}/search`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify({ searchTerm: name, searchBook: recipeBook, searchUrl: recipeUrl }),
      })

      if (!recipesResponse.ok) {
        throw Error("Failed to get recipes")
      }

      return await recipesResponse.json()
    } catch (error) {
      this.onError && this.onError("Failed to create recipe")

      return []
    }
  }

  async updateRecipe(recipe, onComplete) {
    try {
      const token = await window.authorise()

      await fetch(`${this.getAttribute("target")}/${recipe.id}`, {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(recipe),
      })

      return await onComplete()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }
}

customElements.define("create-recipe", CreateRecipe)
