import { FormControlLabel, IconButton } from "@material-ui/core"
import { PhotoCamera } from "@material-ui/icons"
import { authenticate } from "../../Shared/Authenticator"
import React, { useRef } from "react"

/**
 * @param {React.MutableRefObject<HTMLInputElement | null>} inputRef
 * @returns {Promise<void>}
 */
async function uploadFile(inputRef) {
  const { current } = inputRef

  if (!current) {
    return
  }

  const { files } = current

  if (!files || !files.length) {
    return
  }

  const file = files.item(0)

  if (!file) {
    return
  }

  const { name, type } = file

  const response = await fetch(
    `./assets/${name}`,
    await authenticate({
      method: "POST",
      body: file,
      headers: { "Content-Type": type },
    })
  )

  if (!response.ok) {
    throw new Error("Image upload failed")
  }

  return await response.json()
}

export const ImageUploader = (props) => {
  const inputRef = useRef(/** @type {HTMLInputElement | null} */ (null))

  return (
    <>
      <input
        ref={inputRef}
        id="upload"
        accept="image/*"
        capture
        type="file"
        hidden
        onChange={async () => {
          const { fileName, thumbFileName } = await uploadFile(inputRef)
          inputRef.current.value = ""
          props.onUploaded({ fileName, thumbFileName })
        }}
      />
      <FormControlLabel
        htmlFor="upload"
        control={
          <IconButton color="primary" component="span">
            <PhotoCamera />
          </IconButton>
        }
        label={"Upload"}
      />
    </>
  )
}
