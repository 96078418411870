import { Dialog } from "@material-ui/core"
import React from "react"

export const RecipeCardDialog = (props) => {
  const { onClose, open, shadowRoot, recipe } = props

  return (
    <Dialog
      fullWidth
      container={() => shadowRoot.querySelector("#container")}
      onClose={onClose}
      aria-labelledby="recipe-dialog-title"
      open={open}
      disableEnforceFocus={true}
      PaperProps={{
        style: {
          width: "100%",
          overflowX: "hidden",
        },
      }}
    >
      <view-recipe target={`./mealplan/recipe/${recipe.id}`}></view-recipe>
    </Dialog>
  )
}
