import React, { useEffect, useRef, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core"
import { authenticate } from "../../Shared/Authenticator"
import { Close } from "@material-ui/icons"
import PinchZoomPan from "react-responsive-pinch-zoom-pan"

export async function getImages() {
  const response = await fetch("./assets", await authenticate({ method: "GET" }))

  if (!response.ok) {
    throw new Error("Failed to get images")
  }

  const images = await response.json()

  return images
}

async function getFile(file, onFileLoaded) {
  const response = await fetch(`./assets/${file}`, await authenticate({ method: "GET", cache: "force-cache" }))

  const blob = await response.blob()

  const url = URL.createObjectURL(blob)

  if (onFileLoaded) {
    onFileLoaded(url)
  }

  return url
}

export const ImageDisplay = (props) => {
  const [source, setSource] = useState("")
  const [dialogSource, setDialogSource] = useState("")

  const ref = useRef()
  const dialogRef = useRef(/** @type {HTMLDivElement | null} */ (null))
  const [open, setOpen] = useState(false)
  const [dialogHeight, setDialogHeight] = useState(/** @type {number | null} */ (null))
  const [selectedFile, setSelectedFile] = useState(null)

  const { files = [], clickDisabled } = props

  useEffect(() => {
    const { current } = dialogRef

    if (!current) {
      return
    }

    setDialogHeight(current.clientHeight)
  }, [dialogRef.current])

  useEffect(() => {
    const { file, thumbnail } = props
    const { fileName, thumbFileName } = file

    getFile(thumbnail ? thumbFileName : fileName, (image) => {
      setSource(image)
    })
  }, [props.file])

  useEffect(() => {
    if (dialogSource || !open) {
      return
    }

    const { file, thumbnail } = props
    const { fileName } = file

    if (!thumbnail) {
      setDialogSource(source)
      return
    }

    getFile(fileName, (image) => {
      setDialogSource(image)
    })
  }, [open])

  useEffect(() => {
    if (!selectedFile) {
      return
    }

    const { fileName } = selectedFile

    getFile(fileName, (image) => {
      setDialogSource(image)
    })
  }, [selectedFile])

  let { height, minHeight } = props

  height = minHeight ? height : height || 200

  return (
    <div ref={ref}>
      <img
        role="button"
        style={{ height, display: "block", margin: "auto", minHeight }}
        src={source}
        onClick={() => {
          if (clickDisabled) {
            return
          }
          setOpen(true)
        }}
      />

      <Dialog
        onClose={() => setOpen(false)}
        fullWidth
        container={ref.current}
        maxWidth="xl"
        open={open}
        disableEnforceFocus={true}
        disablePortal={true}
        ref={dialogRef}
      >
        <DialogTitle disableTypography={true} style={{ padding: 0 }}>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: 0, height: dialogHeight ? dialogHeight - 64 - 48 : undefined }}>
          <div style={{ height: "100%", margin: "auto" }}>
            <PinchZoomPan position="center">
              <img style={{ display: "block", margin: "auto" }} src={dialogSource} />
            </PinchZoomPan>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <FileThumbnails files={files} fileSelected={(file) => setSelectedFile(file)} />
        </DialogActions>
      </Dialog>
    </div>
  )
}

const FileThumbnails = ({ files, fileSelected }) => {
  const [resolvedFiles, setResolvedFiles] = useState([])

  useEffect(() => {
    async function getAllThumbnails() {
      const resolvedFiles = []

      for (const file of files) {
        const url = await getFile(file.thumbFileName)

        resolvedFiles.push({ file, url })
      }

      setResolvedFiles(resolvedFiles)
    }

    getAllThumbnails()
  }, [files])

  return (
    <>
      {resolvedFiles.map(({ file, url }, index) => (
        <img src={url} key={index} onClick={() => fileSelected(file)} />
      ))}
    </>
  )
}

export const ImageList = (props) => {
  const { imageSelected, images } = props

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2} style={{ margin: 0, width: "auto", overflowX: "scroll", flexWrap: "nowrap" }}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={4} md={3} key={index} style={{ display: "inline-block" }}>
            <button
              style={{ width: "100%", backgroundColor: "transparent", border: "none" }}
              onClick={() => imageSelected(image)}
            >
              <ImageDisplay file={image.name} />
            </button>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
